exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-best-index-jsx": () => import("./../../../src/pages/best/index.jsx" /* webpackChunkName: "component---src-pages-best-index-jsx" */),
  "component---src-pages-case-index-jsx": () => import("./../../../src/pages/case/index.jsx" /* webpackChunkName: "component---src-pages-case-index-jsx" */),
  "component---src-pages-contact-thanks-index-jsx": () => import("./../../../src/pages/contact/thanks/index.jsx" /* webpackChunkName: "component---src-pages-contact-thanks-index-jsx" */),
  "component---src-pages-firsttime-index-jsx": () => import("./../../../src/pages/firsttime/index.jsx" /* webpackChunkName: "component---src-pages-firsttime-index-jsx" */),
  "component---src-pages-gallery-index-jsx": () => import("./../../../src/pages/gallery/index.jsx" /* webpackChunkName: "component---src-pages-gallery-index-jsx" */),
  "component---src-pages-menu-index-jsx": () => import("./../../../src/pages/menu/index.jsx" /* webpackChunkName: "component---src-pages-menu-index-jsx" */),
  "component---src-pages-private-index-jsx": () => import("./../../../src/pages/private/index.jsx" /* webpackChunkName: "component---src-pages-private-index-jsx" */),
  "component---src-pages-staff-index-jsx": () => import("./../../../src/pages/staff/index.jsx" /* webpackChunkName: "component---src-pages-staff-index-jsx" */),
  "component---src-pages-symptoms-index-jsx": () => import("./../../../src/pages/symptoms/index.jsx" /* webpackChunkName: "component---src-pages-symptoms-index-jsx" */),
  "component---src-templates-0-article-index-jsx": () => import("./../../../src/templates/0_article/index.jsx" /* webpackChunkName: "component---src-templates-0-article-index-jsx" */),
  "component---src-templates-1-top-index-jsx": () => import("./../../../src/templates/1_top/index.jsx" /* webpackChunkName: "component---src-templates-1-top-index-jsx" */),
  "component---src-templates-2-about-index-jsx": () => import("./../../../src/templates/2_about/index.jsx" /* webpackChunkName: "component---src-templates-2-about-index-jsx" */),
  "component---src-templates-4-blog-index-jsx": () => import("./../../../src/templates/4_blog/index.jsx" /* webpackChunkName: "component---src-templates-4-blog-index-jsx" */),
  "component---src-templates-5-privacy-index-jsx": () => import("./../../../src/templates/5_privacy/index.jsx" /* webpackChunkName: "component---src-templates-5-privacy-index-jsx" */)
}

